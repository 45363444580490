*,
::after,
::before {
  box-sizing: border-box;
}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  font-size: 100%;
  font-family: var(--mainFont);
  scroll-behavior: smooth;
} /*16px*/

:root {
  /* colors */
  --primary-50: #f1daba;
  --primary-100: #e3bc8e;
  --primary-200: #dcbb8d;

  /* grey */
  --grey-50: #121212;
  --grey-100: #161616;

  /* rest of the colors */
  --black: #000;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --mainFont: 'Montserrat', sans-serif;
  /* --small-textSize: 0.75rem;
  --medium-textSize: 0.875rem;
  --big-textSize: 1rem; */
  --small-textSize: 12px;
  --medium-textSize: 14px;
  --big-textSize: 16px;
  /* rest of the vars */
  --nav-height: 82.5px;
  --transition: 0.3s ease-in-out all;
  /* box shadow*/
}

h1 {
  font-size: var(--big-textSize);
  color: var(--black);
  font-weight: 600;
  text-transform: uppercase;
}

p {
  font-size: var(--medium-textSize);
  color: var(--black);
  font-weight: 400;
  text-align: justify;
}

a[href^='tel'] {
  color: inherit;
  text-decoration: none;
}
